@import "../../style/Colors.scss";

.errorsMainContainer {
  color: rgba(255, 255, 255, 0.66);
  font-size: 1.5em;
  min-height: 100vh;
  position: relative;
  background: $primaryColor !important;

  .contentContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      font-size: 4em;
      color: #fff;
    }
  }

  .logo {
    height: 400px;
    width: 400px;
    fill: rgba(255, 255, 255, 0.1);
  }
}
