@import "../../style/Colors.scss";

.customSelect {
  .customSelect__menu {
    .customSelect__menu-list {
      .customSelect__option {
        &.customSelect__option--is-selected {
          color: #fff;
          background: $primaryColor;
        }

        &.customSelect__option--is-focused:not(.customSelect__option--is-selected) {
          color: #fff;
          background: lighten($primaryColor, 10);
        }
      }
    }
  }
}

.invalid {
  .customSelect {
    .customSelect__control {
      border: solid 1px $dangerColor;
      box-shadow: none;

      &.customSelect__control--is-focused,
      &.customSelect__control--menu-is-open {
        border: solid 1px $dangerColor;
        box-shadow: none;
      }
    }
  }
}

.valid {
  .customSelect {
    .customSelect__control {
      border: solid 1px $successColor;
      box-shadow: none;

      &.customSelect__control--is-focused,
      &.customSelect__control--menu-is-open {
        border: solid 1px $successColor;
        box-shadow: none;
      }
    }
  }
}
