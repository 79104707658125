@import "../../style/Colors.scss";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 99999;

  .content {
    background: #fff;
    max-width: 100%;
    min-width: 300px;
    margin: 0 auto;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  }
}
