@import "../../style/Colors.scss";

.inlineLoader {
  .logo {
    > svg {
      fill: $secondaryColor;
      height: 1.5em;
      animation: blink 3s linear infinite;
    }

    &.xl {
      margin-bottom: 1rem;

      > svg {
        height: 3em;
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
