@import "../../../style/Colors.scss";

.disconnectButtonContainer {
  .disconnectButton {
    &:hover {
      background: $dangerColor;
      color: #fff;
    }
  }
}
