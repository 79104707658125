@import "../../style/Colors.scss";

.imageUploader {
  border: solid 1px #ccc;

  .thumbnailContainer {
    position: relative;
    display: flex;
    justify-content: center;
    justify-items: center;
    border-left: solid 1px #ccc;
    padding: 10px;

    .deleteImageButton {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .thumbnail {
      max-height: 150px;
    }

    &.thumbnail-white {
      background: #eee;
    }
  }

  .left {
  }
}

.invalid .imageUploader {
  border: 1px solid $dangerColor;
}

.valid .imageUploader {
  border: 1px solid $successColor;
}
