@import "../../style/Colors.scss";

.publicLayoutContent {
  background: $primaryColor !important;
  
  .mainContainer {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      font-weight: bold;
    }
  }
}
