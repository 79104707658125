.datePicker.valid input {
  border-color: #4fb867;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234fb867' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}

.datePicker.valid input:focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 184, 103, 0.25);
}

.datePicker.invalid input {
  border-color: #bd362f;
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23bd362f' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23bd362f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.datePicker.invalid input:focus {
  box-shadow: 0 0 0 0.2rem rgba(189, 54, 47, 0.25);
}

.datePicker.fullWidth .react-datepicker-wrapper,
.datePicker.fullWidth .react-datepicker__input-container {
  display: block;
}
