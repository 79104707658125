$primaryColor: #4084a4;
$secondaryColor: #fbd161;
$dangerColor: #bd362f;
$warningColor: #fbd161;
$successColor: #4fb867;

$mainBackgroundColor: #f0f3f4;

$mainTextColor: #7f7f7f;
$darkTextColor: #313131;
