.menuItem {
  opacity: 0.75;
  border-left: 2px transparent solid;
  padding: 5px 0 5px 20px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > a,
  > button {
    padding: 5px 0;
    min-height: 50px;
  }

  .menuItemTitle {
    text-align: center;

    .menuItemTitleContent {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;

      &:before,
      &:after {
        content: "";
        border-top: 1px solid rgba(255, 255, 255, 0.33);
        margin: 0 20px 0 10px;
        flex: 1 0 20px;
      }

      &:after {
        margin: 0 10px 0 20px;
      }
    }
  }

  &.active {
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
  }

  &:hover:not(.active) {
    opacity: 1;
    background: rgba(0, 0, 0, 0.1);
  }

  &.active {
    border-left: #fff solid 2px;
  }

  & > * {
    display: flex;
    align-self: stretch;
    align-content: center;
    align-items: center;
    width: 100%;
  }

  /* sub items list*/
  ul {
    display: none;

    .menuItem,
    .menuItem.active {
      border: none;
      background: transparent;
    }
  }

  &.opened {
    ul {
      display: block;
    }
  }
}
