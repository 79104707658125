@import '../../../style/Colors.scss';

.blockContainer {
  background: #fff;
  padding: 5px;

  .actionButtons {
    // visibility: hidden;

    > * {
      margin: 0 5px;
    }
  }

  &.editable {
    border: dotted 2px #ccc;
    &:hover {
      border: dashed 2px lighten($primaryColor, 25%);

      .actionButtons {
        visibility: visible;
      }
    }
  }
}
