@import '../../../../style/Colors.scss';

.quoteBlockRenderer {
  border: solid $secondaryColor 1px;

  .quoteText {
    padding: 20px;
    font-style: italic;

    &.trunk {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
