@import "../../style/Colors.scss";

.authBlock {
  border-radius: 10px;
  width: 460px;
  background: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);

  h1 {
    font-size: 2rem;
    margin: 10px 0 0 0;
  }

  img {
    width: 100px;
  }

  .logo {
    fill: $primaryColor;
    width: 60px;
    height: 60px;
  }

  .subtitle {
    color: $secondaryColor;
    font-size: 1.2rem;
    font-weight: bold;
  }
}
