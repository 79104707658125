@import '../../../../style/Colors.scss';

.subtitleBlockRenderer {
  color: $primaryColor;
  font-weight: bold;

  /*
  &.trunk {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }*/
}
