@import "../../style/Colors.scss";

.block {
  background: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

  .blockTitle {
    font-size: 16px;
  }

  .blockContent {
    &.reduced {
      display: none;
    }
  }
}

.block.reducible {
  .title {
    cursor: pointer;
  }
}

.block.success {
  border: 1px $successColor solid;
  box-shadow: none;

  .title {
    background-color: $successColor;
    color: #fff;
  }

  .blockContent {
    padding-top: 1rem;
  }
}

.block.danger {
  border: 1px $dangerColor solid;
  box-shadow: none;

  .title {
    background-color: $dangerColor;
    color: #fff;
  }

  .blockContent {
    padding-top: 1rem;
  }
}

.block.warning {
  border: 1px $warningColor solid;
  box-shadow: none;

  .title {
    background-color: $warningColor;
    color: #fff;
  }

  .blockContent {
    padding-top: 1rem;
  }
}
