@import "../../../style/Colors.scss";

.clinicChoice {
  color: inherit;

  &:hover {
    color: #fff !important;
    background: rgba(0, 0, 0, 0.1);
  }
}
