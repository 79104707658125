@import '../../../../style/Colors.scss';

.titleBlockRenderer {
  font-size: 1.2rem;
  color: $primaryColor;
  font-weight: bold;

  /*
  &.trunk {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }*/
}
