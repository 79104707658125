@import '../../style/Colors.scss';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/root';

.privateLayoutContent {
  &.sidemenuopened {
    .sidemenu {
      left: 0;
    }
  }

  &.sidemenuclosed {
    .sidemenu {
      left: -100%;
    }

    .pageContent,
    .header {
      margin-left: 0;
    }
  }

  .sidemenu {
    overflow: auto;

    background: $primaryColor;
    color: #fff;

    transition: all 0.2s;
    position: fixed;
    bottom: 0;
    z-index: 99;
    box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1) inset;

    @include media-breakpoint-up(md) {
      top: 0;
      width: 240px;
      min-width: 240px;
      max-width: 240px;
    }

    @include media-breakpoint-down(md) {
      top: 60px;

      min-width: auto;
      width: 100%;
      max-width: auto;
    }
  }

  .header {
    transition: all 0.2s;
    position: fixed;
    height: 60px;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f0f3f4;
    z-index: 99;

    @include media-breakpoint-up(md) {
      margin-left: 240px;
    }
  }

  .pageContent {
    transition: all 0.2s;
    flex-grow: 1;
    margin-top: 60px;

    @include media-breakpoint-up(md) {
      margin-left: 240px;
    }

    h1 {
      background: #fff;
      width: 100%;
      padding: 20px;
      font-size: 18px;
      color: #5b5b60;
      margin: 0px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    }
  }
}
